import { userProfilePath, userSettingsPath } from 'config/paths'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { LoadComponent } from 'routes/load-component'

const profile = lazy(() => import('pages/shared/profile'))
const settings = lazy(() => import('pages/shared/settings/settings'))

const userRoutes = (): RouteObject[] => [
  {
    path: userProfilePath(),
    element: <LoadComponent component={profile} />,
  },
  {
    path: userSettingsPath(),
    element: <LoadComponent component={settings} />,
  },
]

export { userRoutes }
