import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToast } from 'hooks/use-toast.hook'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'

export default function useRegister() {
  const { showToast } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isAble, setIsAble] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)

  const {
    authStore: { register },
    profileStore: { getGeneralInformation, generalInfo },
  } = useStores()

  const [error, setError] = useState(null)

  const registerResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t('Please enter a valid email'))
        .required(t('Please enter your email')),
      password: yup.string().required(t('Please enter your password')).min(8, 'Minimum 8 chars'),
      confirmPassword: yup
        .string()
        .required(t('Please re-enter your password'))
        .oneOf([yup.ref('password'), null], "Password doesn't match"),
    })
  )

  const navigateToVerify = () => {
    navigate('/account/verification-email', { replace: true })
  }

  const backTo = () => {
    navigate('/account/login', { replace: true })

    sessionStorage.clear()
  }

  useEffect(() => {
    getGeneralInformation(3)
  }, [])

  const doRegister = async (data: any) => {
    try {
      setError(null)
      const registered = await register(data)

      if (registered) {
        navigateToVerify()
        showToast({
          variant: 'success',
          body: 'Register Successful. Please check email for verification',
        })
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.UNAUTHORIZED ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('Email has already registered'))
      }
    }
  }
  return {
    registerResolver,
    doRegister,
    error,
    backTo,
    isAble,
    setIsAble,
    isShowModal,
    setIsShowModal,
    generalInfo,
  }
}
