import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, challengeApi } from 'services/api'

import { ChallengeAnalyticsModel } from './challenge'

export const AnalyticStoreModel = types
  .model('AnalyticStore')
  .props({
    analytics: types.optional(ChallengeAnalyticsModel, {}),
  })
  .actions((self) => ({
    getChallengesAnalytic: flow(function* () {
      const result: ApiSuccessResult = yield challengeApi.getChallengesAnalytics()

      if (result.ok) applySnapshot(self.analytics, result.data.data)

      return result.ok
    }),
  }))

export type AnalyticStore = Instance<typeof AnalyticStoreModel>
export type AnalyticStoreSnapshot = SnapshotOut<typeof AnalyticStoreModel>
