import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import { useController, useFormContext } from 'react-hook-form'

type InputProps = FormControlProps & {
  label: string
  name: string
  containerClass?: string
  rows?: number
  min?: number
  max?: number
  required?: boolean
  showCounter?: boolean
}

export const ControlledTextArea = (props: InputProps) => {
  const {
    label,
    name,
    containerClass,
    children,
    rows,
    max = 1000,
    min = 0,
    required,
    showCounter = true,
    ...otherProps
  } = props

  const { control } = useFormContext()
  const {
    field: { value = '', onChange, ...field },
    fieldState: { invalid, error },
  } = useController({ control, name })

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Form.Control
        name={name}
        id={name}
        as='textarea'
        isInvalid={invalid}
        value={value ?? ''}
        onChange={onChange}
        {...field}
        {...otherProps}
        autoComplete={name}
        rows={rows}
      >
        {children}
      </Form.Control>
      {showCounter && (
        <div className='mt-1'>
          <p className='m-0 text-neutral-5'>
            {value?.length || min} / {max}
          </p>
        </div>
      )}
      {error && (
        <Form.Control.Feedback type='invalid' className='fw-semibold'>
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
