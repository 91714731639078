import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class UserApi extends ApiCore {
  async getUsersPaymentTotal(payload: Payload = {}) {
    return await this.get({
      path: '/users/transactions/creator',
      payload,
    })
  }

  async getUsersPaymentThisMonth(payload: Payload = {}) {
    return await this.get({
      path: '/users/transactions/creator',
      payload,
    })
  }

  async sendNewEmailVerify(payload: Payload = {}) {
    return await this.post({
      path: '/users/email/send',
      payload,
    })
  }

  async verifyNewEmail(payload: Payload = {}) {
    return await this.patch({
      path: '/users/email',
      payload,
    })
  }
}

export const userApi = new UserApi()
