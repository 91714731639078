import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import LeftSidebar from 'components/side-bar/side-bar'
import TopBar from 'components/top-bar/top-bar'
import { useStores } from 'models'
import { useToggle, useViewport } from 'hooks'
import { BasicToast } from './basic-toast'
import { StackedToast } from './stacked-toast'

const Layout: FunctionComponent = observer(() => {
  const [titleObject, setTitleObject] = useState()

  const {
    appStateStore: { colorScheme, sidebarMode, toggleSidebarMode, toggleDarkMode },
    profileStore: {
      profile: { userType },
    },
  } = useStores()

  const { width } = useViewport()
  const [isMobileMenuOpened, toggleMobileMenu] = useToggle()

  const changeBodyAttribute = (attribute: string, value: string): void => {
    if (document.body) document.body.setAttribute(attribute, value)
  }

  const openMobileMenu = () => {
    if (!document.body) return

    toggleMobileMenu()

    if (isMobileMenuOpened) {
      document.body.classList.remove('sidebar-enable')
    } else {
      document.body.classList.add('sidebar-enable')
    }
  }

  const toggleMenu = () => {
    if (width < 768) return openMobileMenu()

    toggleSidebarMode()
  }

  const changeSidebarModeOnResize = useCallback(() => {
    if (width >= 768 && width <= 1028) {
      toggleSidebarMode('condensed')
    }
  }, [width])

  useEffect(() => {
    changeBodyAttribute('data-layout', 'full')
    changeBodyAttribute('data-layout-mode', 'fluid')
    changeBodyAttribute('data-leftbar-theme', 'default')
  }, [])

  useEffect(() => {
    changeBodyAttribute('data-layout-color', colorScheme)
  }, [colorScheme])

  useEffect(() => {
    changeBodyAttribute('data-leftbar-compact-mode', sidebarMode)
  }, [sidebarMode])

  useEffect(() => {
    window.addEventListener('resize', changeSidebarModeOnResize)

    return () => {
      window.removeEventListener('resize', changeSidebarModeOnResize)
    }
  }, [])

  return (
    <div className='wrapper'>
      <LeftSidebar isLight={false} isCondensed userType={userType} />
      <div className='content-page'>
        <TopBar
          openLeftMenuCallBack={toggleMenu}
          openDarkModeToggled={toggleDarkMode}
          title={titleObject}
        />
        <div className='main-content'>
          <Outlet context={{ titleObject, setTitleObject }} />
        </div>
        <BasicToast />
        <StackedToast />
      </div>
    </div>
  )
})

export default Layout
