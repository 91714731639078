import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { defaultProfile } from 'assets/images'
import { useStores } from 'models'
import { startCase } from 'lodash'

import { profileMenus } from './data'
import ProfileDropdown from './partials/profile-dropdown'
import { PageTitle } from 'components/page-title/page-title'

type TopbarProps = {
  hideLogo?: boolean
  navCssClasses?: string
  openLeftMenuCallBack?: () => void
  openDarkModeToggled?: () => void
  title: {
    icon: string
    title: string
  }
}

const TopBar: FunctionComponent<TopbarProps> = observer((props) => {
  const { navCssClasses, openLeftMenuCallBack, title } = props
  const {
    authStore: { userTitle },
    profileStore: { getUserProfile, profile },
  } = useStores()

  const loadData = async () => {
    try {
      await getUserProfile()
    } catch (error) {}
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className={classNames('navbar-custom border-bottom', navCssClasses)}>
      <div className='container-fluid'>
        <ul className='list-unstyled topbar-menu float-end mb-0'>
          <li className='dropdown'>
            <ProfileDropdown
              userImage={defaultProfile}
              menuItems={profileMenus}
              username={startCase(profile.fullName)}
              userTitle={userTitle}
            />
          </li>
        </ul>
        <div className='d-flex align-items-center gap-2'>
          <a className='button-menu-mobile open-left ps-2 ' onClick={openLeftMenuCallBack}>
            <i className='mdi mdi-menu text-charcoal-3 border rounded px-1' />
          </a>
          {title && <PageTitle icon={title.icon} title={title.title} />}
        </div>
      </div>
    </div>
  )
})

export default TopBar
