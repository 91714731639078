import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'

import { FileUploader } from './file-uploader'
import { ControlledFileUploaderProps, FileType } from './file-uploader.types'

export const ControlledFileUploader = (props: ControlledFileUploaderProps) => {
  const { label, name, containerClass, required, ...otherProps } = props

  const [defaultValue, setDefaultValue] = useState<FileType | undefined>(undefined)

  const [imageFiles, setImageFiles] = useState<any[]>([])

  const { control } = useFormContext()

  const handleRemoveFile = (fileToRemove: any, onChange: (files: any[]) => void) => {
    const updatedFiles = imageFiles.filter((file) => file.id !== fileToRemove.id)

    setImageFiles(updatedFiles)
    onChange(updatedFiles)
  }

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
          if (typeof value !== 'undefined' && value?.length > 0) {
            setDefaultValue(value[0])
          }

          return (
            <>
              <FileUploader
                onFileUpload={(files) => {
                  const transformedFiles = files.map((file) => {
                    if (file?.image?.preview) {
                      return {
                        id: `${new Date().getTime()}-${file.image.name}`,
                        name: file.image.name,
                        image: file.image,
                        ogFile: file.ogFile,
                      }
                    }

                    return {
                      id: defaultValue?.id,
                      name: defaultValue?.name,
                      image: undefined,
                      ogFile: undefined,
                    }
                  })

                  const updatedFiles = [...imageFiles, ...transformedFiles]
                  setImageFiles(updatedFiles)
                  onChange(updatedFiles)
                }}
                isInvalid={invalid}
                value={value}
                onFileRemove={() => handleRemoveFile(defaultValue, onChange)}
                {...otherProps}
              />

              {error && (
                <Form.Control.Feedback type='invalid' className='d-block fw-semibold'>
                  {error.message}
                </Form.Control.Feedback>
              )}
            </>
          )
        }}
      />
    </Form.Group>
  )
}
