import React from 'react'
import { useTranslation } from 'react-i18next'

interface PaymentStatusProps {
  status: string | any
}

export const PaymentStatus = ({ status }: PaymentStatusProps) => {
  const { t } = useTranslation()

  switch (status) {
    case 'pending':
      return (
        <div className='payment-status bg-primary-yellow'>
          <i className='icon-clock me-1' />
          <span className='text-capitalize'>{t('Pending')}</span>
        </div>
      )
    case 'completed':
      return (
        <p className='payment-status bg-primary'>
          <i className='icon-check me-1' />
          <span className='text-capitalize'>{t('Completed')}</span>
        </p>
      )
    default:
      return (
        <p className='payment-status bg-neutral-2'>
          <i className='icon-dot me-1' />
          <span className='text-capitalize'>{t('No Earning')}</span>
        </p>
      )
  }
}

export const PaymentStatusTabel = ({ status }: PaymentStatusProps) => {
  const { t } = useTranslation()

  switch (status) {
    case 'succeeded':
      return (
        <p className='payment-status bg-primary'>
          <i className='icon-check me-1' />
          <span className='text-capitalize'>{t('Paid')}</span>
        </p>
      )
    default:
      return (
        <div className='payment-status bg-primary-yellow'>
          <i className='icon-clock me-1' />
          <span className='text-capitalize'>{t('Pending')}</span>
        </div>
      )
  }
}
