import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, userApi } from 'services/api'

import { withReset } from './helpers/with-reset'
import { MonthPaymentModel } from './this-months-payment'
import { TotalPaymentModel } from './total-payment'

export const PaymentStoreModel = types
  .model('PaymentStore')
  .props({
    paymentsThisMonths: types.array(MonthPaymentModel),
    paymentsTotal: types.array(TotalPaymentModel),
    totalPageTotal: types.optional(types.number, 1),
    totalPageMonth: types.optional(types.number, 1),
    tabId: types.optional(types.number, 0),
  })
  .actions(withReset)
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    getThisMonthEarning: flow(function* (payload) {
      self.reset()

      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield userApi.getUsersPaymentThisMonth(payload)

      if (result.ok) {
        applySnapshot(self.paymentsThisMonths, result.data.data.datas)
        self.totalPageMonth = result.data.data.totalPages
      }

      return result.ok
    }),

    getTotalEarning: flow(function* (payload) {
      self.reset()

      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield userApi.getUsersPaymentTotal(payload)

      if (result.ok) {
        applySnapshot(self.paymentsTotal, result.data.data.datas)
        self.totalPageTotal = result.data.data.totalPages
      }

      return result.ok
    }),
  }))

export type PaymentStore = Instance<typeof PaymentStoreModel>
export type PaymentStoreSnapshot = SnapshotOut<typeof PaymentStoreModel>
