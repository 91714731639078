import { ApiCore } from './api-core'
import { Payload } from './api-core.types'
import { buildChallengePayload } from 'services/helpers/buildChallengePayload'
import { SelectedChallengeType } from 'models/selected-challenge'

export class FormDataApi extends ApiCore {
  protected multipart = true

  async updateUserGallery(payload: Payload = {}) {
    return await this.post({
      path: '/users/gallery',
      payload,
    })
  }

  async addChallenges(payload: Payload & SelectedChallengeType) {
    return await this.post({
      path: '/challenges',
      payload: buildChallengePayload(payload),
    })
  }

  async updateChallenges(id: string, payload: Payload = {}) {
    return await this.patch({
      path: `/challenges/${id}`,
      payload,
    })
  }

  async addPersonalInformation(payload: Payload = {}) {
    return await this.post({
      path: '/auth/creator/personal-info',
      payload,
    })
  }
}

export const formDataApi = new FormDataApi()
