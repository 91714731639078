import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ConfirmationModal } from 'components'

import { TableActionsProps } from './table.types'

export const TableActionsHeader = () => {
  const { t } = useTranslation()

  return <div className='text-center'>{t('Actions')}</div>
}

export const TableActions = (props: TableActionsProps) => {
  const { t } = useTranslation()

  const {
    id,
    path,
    actions = ['edit', 'delete'],
    customActions = [],
    deleteConfirmationTitle = t('Delete Item'),
    deleteConfirmationBody = t('Are you sure want to delete this item?'),
    onDelete,
  } = props

  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false)

  const onConfirmDelete = async () => {
    try {
      if (onDelete) await onDelete(id)
    } finally {
      setDeleteConfirmVisible(false)
    }
  }

  return (
    <>
      <div className='text-center gap-1'>
        {customActions.map((action, index) => (
          <OverlayTrigger key={index} placement='top' overlay={<Tooltip>{action.label}</Tooltip>}>
            <Link to='#' className='action-icon' onClick={action.onPress}>
              <i className={action.icon}></i>
            </Link>
          </OverlayTrigger>
        ))}

        {actions.includes('edit') && (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('Edit')}</Tooltip>}>
            <Link to={`${path}/edit/${id}`} className='action-icon me-1'>
              <i className='uil-edit-alt font-22 text-charcoal-3'></i>
            </Link>
          </OverlayTrigger>
        )}

        {actions.includes('delete') && (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('Delete')}</Tooltip>}>
            <Link
              to='#'
              className='action-icon ms-1'
              onClick={(e) => {
                e.preventDefault()
                setDeleteConfirmVisible(true)
              }}
            >
              <i className='uil-trash font-22 text-danger'></i>
            </Link>
          </OverlayTrigger>
        )}
      </div>

      <ConfirmationModal
        visible={deleteConfirmVisible}
        onConfirm={onConfirmDelete}
        onCancel={() => setDeleteConfirmVisible(false)}
        title={deleteConfirmationTitle}
        body={deleteConfirmationBody}
        variant='danger'
      />
    </>
  )
}
