import React, { useMemo } from 'react'

import AdminRoutes from './admin-routes'
import SuperAdminRoutes from './super-admin-routes'
import PrivateRoute from './private-route'
import Layout from 'components/layout/layout'
import AuthRoutes from './shared/auth-routes'
import AuthLayout from 'components/layout/auth-layout'
import RedirectRoute from './redirect-route'
import { useRoutes } from 'react-router-dom'
import ErrorRoutes from './shared/error-routes'
import { useStores } from 'models'

const Routes = () => {
  const {
    profileStore: {
      profile: { isSuperAdmin },
    },
  } = useStores()

  const dynamicRoutes = useMemo(
    () => (isSuperAdmin ? SuperAdminRoutes : AdminRoutes),
    [isSuperAdmin]
  )

  return useRoutes([
    {
      path: '/',
      element: <RedirectRoute route='/' redirectTo='/challenges' />,
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: AuthRoutes,
    },
    {
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: dynamicRoutes,
    },
    {
      path: '/',
      children: ErrorRoutes,
    },
  ])
}

export default Routes
