export const logoBanner = require('./great-logo.png')
export const logoBannerWhite = require('./great-logo-white.svg')
export const logoMini = require('./logo-mini.png')
export const logoToast = require('./logo-toast.png')
export const dummyExample = require('./email-campaign.svg')
export const mailSent = require('./mail-sent.svg')
export const bgImage = require('./bg-gradient.svg')
export const internalServerError = require('./500.svg')
export const noInternetConnection = require('./no-internet.svg')
export const defaultProfile = require('./default-profile.png')
export const notFoundImage = require('./404.svg')
export const defaultImage = require('./image-default.png')
export const emptyState = require('./empty-state.png')
export const loader = require('./loader.json')
export const highlightImage = require('./highlight-image-preview.jpg')
export const charity = require('./charity.png')
export const individual = require('./individual.png')
export const company = require('./company.png')
