import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { OptionProps, components } from 'react-select'

import { converValuesToString, getValuesFromString } from './select.helper'
import { SelectProps } from './select.types'
import Icon from 'components/icon'

export const ControlledSelect = (props: SelectProps) => {
  const {
    name,
    label,
    options,
    containerClass,
    placeholder,
    isMulti = false,
    isClearable = false,
    onChange,
    required,
    hasIcon,
  } = props

  const { control } = useFormContext()

  const { Option } = components

  const IconOption = (props: OptionProps<any, boolean, any>) => {
    return (
      <Option {...props}>
        <div className='d-flex align-items-center gap-1'>
          <Icon size={18} icon={props.data.value} />
          {props.data.label}
        </div>
      </Option>
    )
  }
  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: onFormChange, value }, fieldState: { error, invalid } }) => (
          <>
            <Select
              isMulti={isMulti}
              isClearable={isClearable}
              options={options}
              placeholder={placeholder}
              value={getValuesFromString(value, options)}
              onChange={(val: any) => {
                onChange && onChange(val)
                onFormChange(isMulti ? converValuesToString(val) : val && val.value)
              }}
              className={classNames('react-select', {
                'is-invalid': invalid,
              })}
              classNamePrefix='react-select'
              components={{ Option: hasIcon ? IconOption : Option }}
            />
            {error && (
              <Form.Control.Feedback type='invalid' className='fw-semibold'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />
    </Form.Group>
  )
}
