import {
  challengesEditPath,
  challengesEditPreviewPath,
  challengesFormPath,
  challengesPath,
  challengesPreviewPath,
  paymentPath,
} from 'config/paths'
import { welcomeOrganizerPath } from 'config/paths/auth-path'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { LoadComponent } from 'routes/load-component'

const challenge = lazy(() => import('pages/creator/challenge/list-challenge/challenge'))
const challengeForm = lazy(() => import('pages/creator/challenge/add-challenge/form-challenge'))
const challengeOverview = lazy(() => import('pages/creator/challenge/overview'))
const challengePreview = lazy(() => import('pages/creator/challenge/preview'))

const payment = lazy(() => import('pages/creator/payment/payment'))

const formOrganizer = lazy(() => import('pages/welcome/type-organizer/type-organizer'))
const welcomeOrganizer = lazy(() => import('pages/welcome/welcome-organizer/welcome-organizer'))

export const challengesRoutes = (): RouteObject[] => [
  {
    index: true,
    path: challengesPath(),
    element: <LoadComponent component={challenge} />,
  },
  {
    path: challengesPath(':id'),
    element: <LoadComponent component={challengeOverview} />,
  },
  {
    path: challengesPreviewPath(),
    element: <LoadComponent component={challengePreview} />,
  },
  {
    path: challengesEditPath(':id'),
    element: <LoadComponent component={challengeForm} />,
  },
  {
    path: challengesEditPreviewPath(':id'),
    element: <LoadComponent component={challengePreview} />,
  },
  {
    path: challengesFormPath(),
    element: <LoadComponent component={challengeForm} />,
  },
]

export const paymentRoutes = (): RouteObject[] => [
  {
    path: paymentPath(),
    element: <LoadComponent component={payment} />,
  },
]

export const welcomeOrganizerRoutes = (): RouteObject[] => [
  {
    path: welcomeOrganizerPath(),
    element: <LoadComponent component={welcomeOrganizer} />,
  },
  {
    path: welcomeOrganizerPath(':id'),
    element: <LoadComponent component={formOrganizer} />,
  },
]
