import React, { FunctionComponent, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { useAccountLayout } from 'hooks'
import { BasicToast } from './basic-toast'
import { StackedToast } from './stacked-toast'

const loading = () => <div className=''></div>

const AuthLayout: FunctionComponent = () => {
  useAccountLayout()

  return (
    <>
      <BasicToast />
      <StackedToast />
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </>
  )
}

export default AuthLayout
