import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

type ConfirmationModalProps = {
  title: string
  body: string
  visible?: boolean
  onCancel: () => void
  onConfirm: () => void
  cancelLabel?: string
  confirmLabel?: string
  variant?: string
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation()
  const {
    title,
    body,
    visible,
    onCancel,
    onConfirm,
    cancelLabel = t('Cancel'),
    confirmLabel = t('Yes, continue'),
    variant = 'success',
  } = props

  return (
    <Modal show={visible} onHide={onCancel} centered backdrop='static'>
      <Modal.Header onHide={onCancel} className={classNames('text-white', `bg-${variant}`)}>
        <h4 className='modal-title mt-0'>
          <i className='uil-exclamation-circle me-2' />
          {title}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <h5>{body}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={variant} onClick={onConfirm}>
          {confirmLabel}
        </Button>
        <Button variant='light' onClick={onCancel}>
          {cancelLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
