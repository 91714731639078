import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'

export const MonthPaymentModel = types
  .model('MonthPayment')
  .props({
    id: types.maybe(types.number),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    paymentStatus: types.maybeNull(types.string),
    total: types.maybeNull(types.number),
    details: types.maybeNull(
      types.model({
        id: types.maybe(types.number),
        title: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        joinedMembers: types.optional(types.number, 0),
        challenge: types.maybeNull(
          types.model({
            id: types.maybe(types.number),
            title: types.maybeNull(types.string),
            category: types.maybeNull(types.string),
            level: types.maybeNull(types.string),
            overview: types.maybeNull(types.string),
            startDate: types.maybeNull(types.string),
            joinedMembers: types.maybe(types.number),
            endDate: types.maybeNull(types.string),
            maxMembers: types.maybe(types.number),
            price: types.maybeNull(types.number),
          })
        ),
      })
    ),
  })
  .views((self) => ({
    get startedDate() {
      return moment(self.details?.challenge?.startDate).format('DD/MM/YYYY')
    },
    get endedDate() {
      return moment(self.details?.challenge?.endDate).format('DD/MM/YYYY')
    },
  }))

export type MonthPaymentInstance = Instance<typeof MonthPaymentModel>
export type MonthPayment = Omit<MonthPaymentInstance, symbol>
export type MonthPaymentSnapshot = SnapshotOut<typeof MonthPaymentModel>
