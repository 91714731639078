import { USER_TYPE } from 'config/constant'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'
import { UserType } from 'types/global.types'
import { Media, MediaOrFileType } from './selected-challenge'

export const ProfileModel = types
  .model('Profile')
  .props({
    id: types.maybe(types.number),
    profilePicture: types.maybeNull(types.union(Media, MediaOrFileType)),
    fullName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    userLevel: types.maybeNull(types.number),
    birthday: types.maybeNull(types.string),
  })
  .views((self) => ({
    get userTypeIsSet() {
      return !!self.userLevel
    },
    get userType(): UserType {
      switch (self.userLevel) {
        case 1:
          return 'super-admin'
        case 2:
          return 'admin'
        default:
          return 'admin'
      }
    },
    get isAdmin() {
      return self.userLevel === USER_TYPE.ADMIN.level
    },
    get isSuperAdmin() {
      return self.userLevel === USER_TYPE.SUPER_ADMIN.level
    },
    get formatedDateJoined() {
      return moment(self.createdAt).format('Do MMM YYYY')
    },
    get formatedBirthday() {
      return moment(self.birthday).format('DD MMM YYYY')
    },
  }))

export type ProfileInstance = Instance<typeof ProfileModel>
export type Profile = Omit<ProfileInstance, symbol>
export type ProfileSnapshot = SnapshotOut<typeof ProfileModel>

export const PaymentAnalyticsModel = types.model('PaymentAnalytics').props({
  total: types.optional(types.number, 0),
  totalPermonth: types.optional(types.number, 0),
  status: types.maybeNull(types.string),
})

export type PaymentAnalyticsInstance = Instance<typeof PaymentAnalyticsModel>
export type PaymentAnalytics = Omit<PaymentAnalyticsInstance, symbol>
export type PaymentAnalyticsSnapshot = SnapshotOut<typeof PaymentAnalyticsModel>
