import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class ProfileApi extends ApiCore {
  async getProfile(payload: Payload = {}) {
    return await this.get({
      path: '/users/me',
      payload,
    })
  }

  async deleteAccount(payload: Payload = {}) {
    return await this.delete({
      path: '/users/delete',
      payload,
    })
  }

  async changePassword(payload: Payload = {}) {
    return await this.patch({
      path: '/users/password',
      payload,
    })
  }

  async updateProfile(id: number, payload: Payload = {}) {
    return await this.patch({
      path: `/users/${id}`,
      payload,
    })
  }

  async getPaymentAnalytics(payload: Payload = {}) {
    return await this.get({
      path: '/users/transactions/creator/analytics',
      payload,
    })
  }

  async updateUserGallery(payload: Payload = {}) {
    return await this.post({
      path: '/users/gallery',
      payload,
    })
  }

  async getPhoneNumber(payload = {}) {
    return await this.get({ path: 'phone-codes', payload })
  }

  async getGeneralInformation(id: number | string) {
    return await this.get({ path: `general-information/${id}` })
  }
}

export const profileApi = new ProfileApi()
