import { useMemo } from 'react'
import { IconType } from 'types/global.types'

export const useIcon = (name: string | IconType) => {
  return useMemo(() => {
    switch (name) {
      case 'indoor':
        return 'icon-home-open'
      case 'outdoor':
        return 'icon-tree'
      case 'virtual':
        return 'icon-virtual'
      case 'adventure':
        return 'icon-adventure text-primary-green'
      case 'health':
        return 'icon-heart-rate text-primary-blue'
      case 'sport':
        return 'icon-streght text-primary-red'
      case 'wellness':
        return 'icon-meditation text-primary-yellow'
      default:
        return name
    }
  }, [name])
}
