import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'

export type LoginData = {
  email: string
  password: string
}

export default function useLogin() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    authStore: { login, isAuthenticated, registrationCompletion },
  } = useStores()

  const [error, setError] = useState(null)

  const loginResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t('Please enter a valid email'))
        .required(t('Please enter your email')),
      password: yup.string().required(t('Please enter your password')),
    })
  )

  const navigateToDashboard = () => {
    navigate('/challenges', { replace: true })
  }

  const navigateToVerify = () => {
    navigate('account/verification-email', { replace: true })
  }

  const navigateWelcome = () => {
    navigate('account/welcome-organizer', { replace: true })
  }

  const doLogin = async ({ email, password }: LoginData) => {
    try {
      setError(null)
      const submit = await login(email, password)

      if (submit && isAuthenticated) {
        return navigateToDashboard()
      } else if (submit && registrationCompletion?.lastStep === 1) {
        return navigateToVerify()
      } else if (submit && registrationCompletion?.lastStep === 2) {
        return navigateWelcome()
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.UNAUTHORIZED ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('Email or password is invalid!'))
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigateToDashboard()
    }
  }, [isAuthenticated])

  return { loginResolver, doLogin, error }
}
