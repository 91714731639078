import { MenuItemType } from './menu.types'

export const SUPER_ADMIN_MENU_ITEMS: MenuItemType[] = [
  {
    key: 'navigation',
    label: 'Navigation',
    isTitle: true,
  },
  {
    key: 'challenge',
    label: 'Events',
    url: '/challenges',
    icon: 'icon-medal',
  },
]
