import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'

export const ParticipantsModel = types
  .model('Participants')
  .props({
    id: types.maybe(types.number),
    ticketType: types.maybeNull(types.string),
    participantName: types.maybeNull(types.string),
    participantEmail: types.maybeNull(types.string),
    participantPhoneNumberCode: types.maybeNull(types.string),
    participantPhoneNumber: types.maybeNull(types.string),
    responderName: types.maybeNull(types.string),
    responderEmail: types.maybeNull(types.string),
    responderRelationship: types.maybeNull(types.string),
    responderPhoneNumberCode: types.maybeNull(types.string),
    responderPhoneNumber: types.maybeNull(types.string),
    checkInAt: types.maybeNull(types.string),
  })
  .views((self) => ({
    get checkInDate() {
      return moment(self.checkInAt).format('DD/MM/YYYY')
    },
  }))

export type ParticipantsInstance = Instance<typeof ParticipantsModel>
export type Participants = Omit<ParticipantsInstance, symbol>
export type ParticipantsSnapshot = SnapshotOut<typeof ParticipantsModel>
