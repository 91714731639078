import React from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

type SubmitButtonProps = ButtonProps & {
  loading?: boolean
  loadingProps?: SpinnerProps
  containerClass?: string
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const {
    loading,
    loadingProps,
    children,
    containerClass = 'mt-0 text-white fw-bold',
    ...buttonProps
  } = props

  const { t } = useTranslation()

  return (
    <Button
      type='submit'
      variant='primary'
      className={containerClass}
      {...buttonProps}
      disabled={loading}
    >
      {loading && (
        <>
          <Spinner
            as='span'
            animation='grow'
            size='sm'
            role='status'
            aria-hidden='true'
            className='me-1'
            variant='light'
            {...loadingProps}
          />
        </>
      )}

      {loading ? t('Submitting') : children}
    </Button>
  )
}
