import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card, Col, Row, Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TableHeaderProps } from './table.types'
import { getFilterFromSearchParams } from './table.helper'
import { FormInput } from 'components'
import Icon from 'components/icon'

export const TableHeader = (props: TableHeaderProps) => {
  const { t } = useTranslation()
  const { filters, onFilter } = props

  const [searchParams] = useSearchParams()

  const methods = useForm()
  const { handleSubmit, setValue, register } = methods

  useEffect(() => {
    const filters = getFilterFromSearchParams(searchParams)

    Object.entries(filters).forEach(([key, value]) => {
      setValue(key, value)
    })
  }, [])

  return (
    <Card.Header className='p-0 table-card-header'>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Row className='gap-2 gap-xl-0 flex-column flex-xl-row'>
            <Col xl={8}>{filters}</Col>
            <Col xl={4}>
              <div className='table-search'>
                <FormInput
                  type='text'
                  name='search'
                  placeholder={t('Type to search..')}
                  register={register}
                />
                <button type='submit' className='search-icon'>
                  <Icon icon='icon-search' size={24} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Card.Header>
  )
}
