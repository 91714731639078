{
  "0% platform fee deducted from each transaction": "0% platform fee deducted from each transaction",
  "Account Name": "Account Name",
  "Account Number": "Account Number",
  "Active Events": "Active Events",
  "Add": "Add",
  "Add New Challenge": "Add New Challenge",
  "Add at least 1 Images": "Add at least 1 Images",
  "Add at least 1 challenge benefit": "Add at least 1 challenge benefit",
  "Add at least 1 challenge requirement": "Add at least 1 challenge requirement",
  "Add at least 1 event task": "Add at least 1 event task",
  "Additional link": "Additional link",
  "Adventure": "Adventure",
  "All Events": "All Events",
  "Apply": "Apply",
  "Apply Filter": "Apply Filter",
  "Apply Sort": "Apply Sort",
  "Are you sure want to delete this item?": "Are you sure want to delete this item?",
  "Are you sure want to this account?": "Are you sure want to this account?",
  "Ascending Name": "Ascending Name",
  "Back": "Back",
  "Bank Account": "Bank Account",
  "Bank Name": "Bank Name",
  "Cancel": "Cancel",
  "Category": "Category",
  "Challenge benefit": "Challenge benefit",
  "Event benefits": "Event benefits",
  "Event Details": "Event Details",
  "Event is successfully deleted": "Event is successfully deleted",
  "Challenge members maximum": "Challenge members maximum",
  "Event Name": "Event Name",
  "Enter event name": "Enter event name",
  "Event overview": "Event overview",
  "Challenge requirement": "Challenge requirement",
  "Event requirements": "Event requirements",
  "Challenge tasks": "Challenge tasks",
  "Change Password": "Change Password",
  "Choose Category": "Choose Category",
  "Choose Status": "Choose Status",
  "Choose a category": "Choose a category",
  "Choose a level": "Choose a level",
  "Choose level": "Choose level",
  "Choose maximum member": "Choose maximum member",
  "Completed": "Completed",
  "Completed Events": "Completed Events",
  "Confirm New Password": "Confirm New Password",
  "Confirm your password": "Confirm your password",
  "Continue": "Continue",
  "Cover": "Cover",
  "Create New": "Create New",
  "Create your task and share your event to the world.": "Create your task and share your event to the world.",
  "Current Password": "Your current password",
  "Cycling": "Cycling",
  "Date & Time": "Date & Time",
  "Delete": "Delete",
  "Delete Account": "Delelete Account",
  "Delete Event": "Delete Event",
  "Descending Name": "Descending Name",
  "Description": "Description",
  "Drop files here or click to upload.": "Drop files here or click to upload.",
  "Earnings this month": "Earnings this month",
  "Earnings total": "Earnings total",
  "Edit": "Edit",
  "Edit Challenge": "Edit Challenge",
  "Edit Images": "Edit Images",
  "Edit Profile": "Edit Profile",
  "Enter a description": "Enter a description",
  "Enter a price": "Enter a price",
  "Failed": "Failed",
  "Failed to delete Account": "Failed to delete Account",
  "Failed to delete challenge": "Failed to delete challenge",
  "Failed to setting bank account": "Failed to setting bank account",
  "File too large": "File too large",
  "Fill fields below to change your password": "Fill fields below to change your password",
  "Fill fields below to set up your account": "Fill fields below to set up your account",
  "Fill the forms to continue to the next step": "Fill the forms to continue to the next step",
  "Filter": "Filter",
  "Food": "Food",
  "Health": "Health",
  "Highest Maximum Member": "Highest Maximum Member",
  "Highest Price": "Highest Price",
  "Joined": "Joined",
  "Latest Created": "Latest Created",
  "Level": "Level",
  "Lowest Maximum Member": "Lowest Maximum Member",
  "Lowest Price": "Lowest Price",
  "Max Characters is 1000": "Max Characters is 1000",
  "Maximum Member": "Maximum Member",
  "Maximum file is 2MB, must be Jpg or Png.": "Maximum file is 2MB, must be Jpg or Png.",
  "Maximum members": "Maximum members",
  "Meditation": "Meditation",
  "Menu": "Menu",
  "Min price": "Min price",
  "Minimum member is 1": "Minimum member is 1",
  "Minimum price is 1": "Minimum price is 1",
  "My Events": "My Events",
  "My Profile": "My Profile",
  "Name": "Name",
  "New Password": "New Password",
  "Oldest Created": "Oldest Created",
  "Ongoing": "Ongoing",
  "Others": "Others",
  "Overview": "Overview",
  "Please enter  member": "Please enter  member",
  "Please enter  price": "Please enter  price",
  "Please enter a benefit": "Please enter a benefit",
  "Please enter a image": "Please enter a image",
  "Please enter a overview": "Please enter a overview",
  "Please enter a price": "Please enter a price",
  "Please enter a requirement": "Please enter a requirement",
  "Please enter a task": "Please enter a task",
  "Please enter a title": "Please enter a title",
  "Please enter max member": "Please enter max member",
  "Please enter your account name": "Please enter your account name",
  "Please input title": "Please input title",
  "Please input your account number": "Please input your account number",
  "Please select a category": "Please select a category",
  "Please select a level": "Please select a level",
  "Please select date and time": "Please select date and time",
  "Please select your bank": "Please select your bank",
  "Price (Euro)": "Price (Euro)",
  "Price Range": "Price Range",
  "Profile Picture": "Profile Picture",
  "Profile Setting": "Profile Setting",
  "Reset": "Reset",
  "Running": "Running",
  "Save": "Save",
  "Setting": "Setting",
  "Sort": "Sort",
  "Sport": "Sport",
  "Strength": "Strength",
  "Submitting": "Submitting",
  "Swimming": "Swimming",
  "Task": "Task",
  "Task Name": "Task Name",
  "There no event yet": "There no event yet",
  "There no media yet": "There's no media yet",
  "Timeline Range": "Timeline Range",
  "Type a challenge benefits..": "Type a challenge benefits..",
  "Type a challenge requirement..": "Type a challenge requirement..",
  "Type the overview here..": "Type the overview here..",
  "Type to search": "Type to search",
  "Upcoming": "Upcoming",
  "Wellness": "Wellness",
  "Winter Sport": "Winter Sport",
  "Your account is successfully deleted": "Your account is successfully deleted",
  "Your new password": "Your new password",
  "Add relevant tags": "Add relevant tags",
  "Event Label": "Event Label",
  "Event Categories": "Event Categories",
  "Select event category": "Select event category",
  "Location": "Location",
  "Enter location": "Enter location",
  "Participant Limit": "Participant Limit",
  "Set participant limit": "Set participant limit",
  "Provide a brief event overview": "Provide a brief event overview",
  "Provide a brief event description": "Provide a brief event description",
  "Repellat nulla earum ut reprehenderit": "Repellat nulla earum ut reprehenderit",
  "Logo": "Logo",
  "Images (Highlight 1 image, Max 20)": "Images (Highlight 1 image, Max 20)",
  "Paste YouTube link": "Paste YouTube link",
  "YouTube link": "YouTube link",
  "Event Price": "Event Price",
  "Ticket Description": "If your ticket includes merchandise, the event organizer will manage the distribution, shipping, and any details directly with participants. These are not handled by the Great platform.",
  "Select Currency": "Select Currency",
  "Select your currency": "Select your currency",
  "Ticket Title": "Ticket Title",
  "Enter ticket title": "Enter ticket title",
  "Whats Included": "What's Included",
  "List whats included": "List what's included (e.g., access, equipment)",
  "Type the description here": "Type the description here..",
  "Ticket Pricing": "Ticket Pricing",
  "Enter ticket price": "Enter ticket price",
  "Upload 2 Images For Merchandise": "Upload 2 Images For Merchandise",
  "Additional Information": "Additional Information",
  "Merchandise will be given": "e.g., Merchandise will be given out manually by event organizer on the day of the event.",
  "Tickets": "Tickets",
  "Task Title": "Task Title",
  "Task title": "Task title",
  "Choose a date & time": "Choose a date & time",
  "Event additional link": "Event additional link",
  "Task Description": "Task Description",
  "Describe the task and expectations": "Describe the task and expectations",
  "Safety Tips & Guidelines for Participants (Optional)": "Safety Tips & Guidelines for Participants (Optional)",
  "Safety Guidelines": "Safety Guidelines",
  "Enter safety instructions and precautions for participants": "Enter safety instructions and precautions for participants",
  "Set up your event": "Set up your event",
  "Event Media": "Event Media",
  "Please enter a valid valid Youtube url": "Please enter a valid valid Youtube url",
  "Create Event": "Create Event",
  "Edit Event": "Edit Event",
  "Task (Optional)": "Task (Optional)",
  "Price": "Price",
  "Media": "Media",
  "Details": "Details",
  "Preview": "Preview",
  "Please select a label": "Please select a label",
  "Please upload logo": "Please upload logo",
  "Please upload at least one image": "Please upload at least one image",
  "Please upload images": "Please upload images",
  "Please enter a valid Youtube url": "Please enter a valid Youtube url",
  "Please enter a name": "Please enter a name",
  "Please enter a location": "Please enter a location",
  "Please enter a participant limit": "Please enter a participant limit",
  "Please select a currency": "Please select a currency",
  "Please enter a included": "Please enter a included",
  "Please enter a description": "Please enter a description",
  "Please enter a pricing": "Please enter a pricing",
  "Please enter at least 1 ticket": "Please enter at least 1 ticket",
  "Events": "Events",
  "Event tasks": "Event tasks",
  "Submit Event": "Submit Event",
  "Save as Draft": "Save as Draft",
  "Copied!": "Copied!",
  "Copy": "Copy",
  "Quantity": "Quantity",
  "Ticket Quantity": "Ticket Quantity",
  "Enter ticket quantity": "Enter ticket quantity",
  "Please enter a quantity": "Please enter a quantity",
  "Share": "Share",
  "Images": "Images",
  "Start Date & Time": "Start Date & Time",
  "End Date & Time": "End Date & Time",
  "Too many files": "Too many files",
  "Recent Order": "Recent Order",
  "Failed!": "Failed!",
  "End date must be after start date": "End date must be after start date",
  "Email": "Email",
  "Date of Birth": "Date of Birth",
  "Phone Number": "Phone Number",
  "First Responder": "First Responder",
  "Organization Website / Social Media Link": "Organization Website / Social Media Link",
  "Update Profile Success": "Update Profile Success",
  "Company Name": "Company Name",
  "Enter company name": "Enter company name",
  "Company Address": "Company Address",
  "Enter company address": "Enter company address",
  "Company Website": "Company Website",
  "Enter company website": "Enter company website",
  "Charity Logo": "Charity Logo",
  "Charity Organization Name": "Charity Organization Name",
  "Enter charity name": "Enter charity name",
  "Charity Organization Address": "Charity Organization Address",
  "Enter charity address": "Enter charity address",
  "Chairty Website / Social Media Link": "Chairty Website / Social Media Link",
  "Enter charity website": "Enter charity website",
  "Charity Instagram": "Charity Instagram",
  "Enter charity Instagram": "Enter charity Instagram",
  "Search Events": "Search Events"
}
