import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import moment from 'moment'
import { Media, Thumbnail } from './selected-challenge'

const Challenger = types.model('Challenger', {
  id: types.maybeNull(types.number),
  progress: types.maybeNull(types.number),
  checkInAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

const Merchandise = types.model('Merchandise', {
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  media_type: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

const Ticket = types.model('Ticket', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  tooltip: types.maybeNull(types.string),
  overview: types.maybeNull(types.string),
  includes: types.maybeNull(types.array(types.string)),
  max_quantity: types.maybeNull(types.number),
  additionalInformation: types.maybeNull(types.string),
  merchandises: types.maybeNull(types.array(Merchandise)),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

const Creator = types.model('Creator', {
  id: types.maybeNull(types.number),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userLevel: types.maybeNull(types.number),
  birthday: types.maybeNull(types.string),
  weight: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  gender: types.maybeNull(types.string),
  occupation: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  phoneNumberCode: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  overview: types.maybeNull(types.string),
  confirmedAt: types.maybeNull(types.string),
  isPreferenceAnswered: types.boolean,
  AIBalance: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
  CreatorPersonalInfo: types.maybeNull(types.string),
  challengeAnalytics: types.model({
    all: types.model({
      created: types.maybeNull(types.number),
      joined: types.maybeNull(types.number),
    }),
    active: types.model({
      created: types.maybeNull(types.number),
      joined: types.maybeNull(types.number),
    }),
    completed: types.model({
      created: types.maybeNull(types.number),
      joined: types.maybeNull(types.number),
    }),
  }),
  gallery: types.maybeNull(types.frozen()),
  transactions: types.maybeNull(types.frozen()),
  preferences: types.maybeNull(types.frozen()),
  meal_plans: types.maybeNull(types.frozen()),
  exercises: types.maybeNull(types.frozen()),
  calorieIntake: types.model({
    id: types.maybeNull(types.number),
    calorieNeeds: types.maybeNull(types.number),
    protein: types.maybeNull(types.number),
    carbs: types.maybeNull(types.number),
    fats: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  }),
})

export const ChallengeModel = types
  .model('Challenge')
  .props({
    id: types.maybeNull(types.number),
    category: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    level: types.maybeNull(types.string),
    overview: types.maybeNull(types.string),
    benefits: types.maybeNull(types.array(types.maybeNull(types.string))),
    requirements: types.maybeNull(types.array(types.maybeNull(types.string))),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    maxMembers: types.maybeNull(types.number),
    additionalInformation: types.maybeNull(types.string),
    eventStatus: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    joinedMembers: types.maybeNull(types.number),
    taskCount: types.maybeNull(types.number),
    progress: types.maybeNull(types.number),
    safetyTipsAndGuidelines: types.maybeNull(types.string),
    termAndCondition: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    latitude: types.maybeNull(types.string),
    longitude: types.maybeNull(types.string),
    distance: types.maybeNull(types.number),
    type: types.maybeNull(types.number),
    videoLink: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    add_ons: types.maybeNull(types.string),
    creator: types.maybeNull(Creator),
    media: types.maybeNull(Media),
    thumbnails: types.maybeNull(types.array(Thumbnail)),
    challenger: types.maybeNull(Challenger),
    tickets: types.maybeNull(types.array(Ticket)),
    merchandises: types.maybeNull(types.array(Merchandise)),
  })
  .views((self) => ({
    get startedDate() {
      return moment(self.startDate).format('Do MMM')
    },
    get endedDate() {
      return moment(self.endDate).format('Do MMM')
    },
    get status() {
      if (moment.utc().isBefore(moment.utc(self.startDate))) {
        return 'upcoming'
      } else if (moment.utc().isBetween(moment.utc(self.startDate), moment.utc(self.endDate))) {
        return 'ongoing'
      } else moment.utc().isAfter(moment.utc(self.endDate))
      return 'completed'
    },
  }))

export type ChallengeInstance = Instance<typeof ChallengeModel>
export type Challenge = Omit<ChallengeInstance, symbol>
export type ChallengeSnapshot = SnapshotOut<typeof ChallengeModel>

export const ChallengeAnalyticsModel = types.model('ChallengeAnalytics').props({
  all: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
  active: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
  completed: types.maybe(
    types.model({
      created: types.maybe(types.maybeNull(types.number)),
      joined: types.maybe(types.maybeNull(types.number)),
    })
  ),
})

export type ChallengeAnalyticsInstance = Instance<typeof ChallengeAnalyticsModel>
export type ChallengeAnalytics = Omit<ChallengeAnalyticsInstance, symbol>
export type ChallengeAnalyticsSnapshot = SnapshotOut<typeof ChallengeAnalyticsModel>
