import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'

export default function useRegisterVerification() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    authStore: { verifyEmailToken, isAuthenticated, resendVerifyEmail, email },
  } = useStores()

  const [error, setError] = useState(null)

  const tokenResolver = yupResolver(
    yup.object().shape({
      token: yup
        .number()
        .required(t('Please enter your code'))
        .typeError(t('Please enter your code')),
    })
  )

  const backTo = async () => {
    navigate(-1)
    sessionStorage.clear()
  }

  const navigateToDashboard = () => {
    navigate('/account/welcome-organizer', { replace: true })
  }

  const doVerify = async (data: any) => {
    try {
      const submit = await verifyEmailToken({ email, ...data })

      if (submit) {
        navigateToDashboard()
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('The code you entered is incorrect!'))
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) navigateToDashboard()
  }, [isAuthenticated])

  const doResendCode = async () => {
    try {
      await resendVerifyEmail({ email })
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('You already requested, wait for 5 minutes for resend'))
      }
    }
  }
  return { tokenResolver, doVerify, error, backTo, doResendCode }
}
