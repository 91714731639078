import React from 'react'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'

type StatisticsWidgetProps = {
  textClass?: string
  bgClass?: string
  icon?: string
  title: string
  stats?: number
}

const StatisticsWidget = ({ textClass, bgClass, icon, title, stats }: StatisticsWidgetProps) => {
  return (
    <Card className={classNames('widget-flat', bgClass)}>
      <Card.Body className='widget-color'>
        {icon && (
          <div className='float-end'>
            <i className={classNames(icon, 'widget-icon')}></i>
          </div>
        )}
        <h1 className={classNames('my-2 text-black', textClass || null)}>{stats}</h1>
        <h4 className={classNames('fw-normal', 'mb-1', textClass || 'text-black')}>{title}</h4>
      </Card.Body>
    </Card>
  )
}

export default StatisticsWidget
