import { buildChallengePayload } from 'services/helpers/buildChallengePayload'
import { ApiCore } from './api-core'
import { Payload } from './api-core.types'
import { SelectedChallengeType } from 'models/selected-challenge'

export class ChallengeApi extends ApiCore {
  async deleteChallenges(id: string) {
    return await this.delete({
      path: `/challenges/${id}`,
    })
  }

  async getChallenges(payload: Payload = {}) {
    return await this.get({
      path: '/challenges/created',
      payload,
    })
  }

  async getChallengesAnalytics(payload: Payload = {}) {
    return await this.get({
      path: 'challenges/analytics',
      payload,
    })
  }

  async getChallengesDetails(id: string, payload: Payload = {}) {
    return await this.get({
      path: `challenges/${id}`,
      payload,
    })
  }

  async addChallenges(payload: Payload & SelectedChallengeType) {
    return await this.post({
      path: '/challenges',
      payload: buildChallengePayload(payload),
    })
  }

  async updateChallenges(id: string, payload: Payload = {}) {
    return await this.patch({
      path: `/challenges/${id}`,
      payload,
    })
  }

  async getChallengesParticipants(id: string, payload: Payload = {}) {
    return await this.get({
      path: `/challenges/${id}/participants`,
      payload,
    })
  }
}

export const challengeApi = new ChallengeApi()
