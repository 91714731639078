import React, { FC, SyntheticEvent, memo, useState } from 'react'
import classNames from 'classnames'

import { ImageProps } from './index.types'

import { defaultProfile } from 'assets/images'

const Image: FC<ImageProps> = (props) => {
  const { image, alt, fallback, width = '100%', height = 'auto', className } = props

  const [src, setSrc] = useState(image || fallback || defaultProfile)

  const handleError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.onerror = null
    setSrc(defaultProfile)
    event.currentTarget.classList.add('img-error')
  }

  return (
    <img
      src={src}
      className={classNames('custom-image', className, {
        'img-error': src === defaultProfile,
      })}
      style={{ width, height }}
      alt={alt || 'An awesome image'}
      onError={handleError}
      loading='lazy'
      decoding='async'
    />
  )
}

export default memo(Image)
