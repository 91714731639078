import React, { FC, useMemo } from 'react'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

interface StatusComponentProps {
  status: string
}

export const StatusStats: FC<StatusComponentProps> = ({ status }) => {
  const { t } = useTranslation()

  const statusElement = useMemo(() => {
    switch (status) {
      case 'published':
        return (
          <div className='status-card bg-white rounded'>
            <Icon icon='icon-double-check' size={16} className='text-primary-blue' />
            <p className='status-title m-0 fw-600 text-primary-blue'>{t('Published')}</p>
          </div>
        )
      case 'in-review':
        return (
          <div className='status-card bg-white rounded'>
            <Icon icon='icon-clock' size={16} className='text-primary-yellow' />
            <p className='status-title m-0 fw-600 text-primary-yellow'>{t('In Review')}</p>
          </div>
        )
      case 'draft':
        return (
          <div className='status-card bg-white rounded'>
            <Icon icon='icon-fire' size={16} className='text-primary-red' />
            <p className='status-title m-0 fw-600 text-primary-red'>{t('Draft')}</p>
          </div>
        )
      default:
        return (
          <div className='status-card bg-white rounded'>
            <Icon icon='icon-clock' size={16} className='text-primary-yellow' />
            <p className='status-title m-0 fw-600 text-primary-yellow'>{t('In Review')}</p>
          </div>
        )
    }
  }, [status])

  return statusElement
}
