import { ProfileOption } from './partials/types'

const profileMenus: ProfileOption[] = [
  {
    label: 'My Profile',
    icon: 'icon-user',
    redirectTo: '/user/profile',
  },
  {
    label: 'Log Out',
    icon: 'icon-logout',
    redirectTo: '/account/logout',
  },
]

export { profileMenus }
