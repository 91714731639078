import React, { FunctionComponent, useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, InputGroup, FormControl, Button, Dropdown } from 'react-bootstrap'
import { PhoneInputWithDropdownProps, PhoneInputProps } from './phone-number.props'

const PhoneCodeDropdown: FunctionComponent<PhoneInputProps> = ({
  data,
  value,
  onChange,
  placeholder,
  error,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [displayedLabel, setDisplayedLabel] = useState('🇬🇷 +30')

  useEffect(() => {
    if (!value && data?.length > 0) {
      const defaultItem = data[0]
      setDisplayedLabel(`${defaultItem.countryIcon} +${defaultItem.phoneCode}`)
      onChange(defaultItem.phoneCode)
    } else if (value) {
      const selectedItem = data.find((item: any) => item.phoneCode === value)
      setDisplayedLabel(
        selectedItem ? `${selectedItem.countryIcon} +${selectedItem.phoneCode}` : placeholder
      )
    }
  }, [value, data])

  const handlePhoneCodeChange = (code: string) => {
    const selectedItem = data.find((item: any) => item.phoneCode === code)
    setDisplayedLabel(`${selectedItem.countryIcon} +${selectedItem.phoneCode}`)
    onChange(code)
    setDropdownVisible(false)
  }

  return (
    <Dropdown show={isDropdownVisible} onToggle={() => setDropdownVisible(!isDropdownVisible)}>
      <Dropdown.Toggle
        as={Button}
        variant='outline-secondary'
        className={error ? 'is-invalid' : ''}
      >
        {displayedLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className='custom-dropdown-menu' align={'start'}>
        {data?.map((item: any) => (
          <Dropdown.Item key={item.id} onClick={() => handlePhoneCodeChange(item.phoneCode)}>
            {item.countryIcon} {item.countryName} (+{item.phoneCode})
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const PhoneInputWithDropdown: FunctionComponent<PhoneInputWithDropdownProps> = ({
  name,
  phoneCodeName,
  label,
  placeholder,
  data,
  required = false,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    if (data?.length > 0) {
      setValue(phoneCodeName, data[0].phoneCode)
    }
  }, [data, setValue, phoneCodeName])

  return (
    <Form.Group className='mb-3'>
      <Form.Label>
        {label} {required && <span className='text-danger'>*</span>}
      </Form.Label>
      <InputGroup>
        <Controller
          name={phoneCodeName}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <PhoneCodeDropdown
              data={data}
              value={value}
              onChange={onChange}
              placeholder='Select a phone code'
              error={error}
            />
          )}
        />
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              value={value}
              onChange={onChange}
              isInvalid={!!error}
              placeholder={placeholder || 'Enter your phone number'}
            />
          )}
        />
        <Form.Control.Feedback type='invalid'>{errors[name]?.message}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
}
