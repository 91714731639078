import { Payload } from 'services/api'

import { SelectedChallengeType, TaskType, TicketType } from 'models/selected-challenge'

export const buildChallengePayload = (payload: Payload & SelectedChallengeType) => {
  const {
    title,
    label,
    category,
    startDate,
    endDate,
    location,
    longitude,
    latitude,
    overview,
    description,
    media,
    videoLink,
    tickets,
    tasks,
    safetyTipsAndGuidelines,
    additionalInfo,
    thumbnails,
  } = payload

  return {
    title,
    description,
    category,
    overview,
    participantLimit: tickets
      .map((ticket: TicketType) => ticket.max_quantity)
      .reduce((a: any, b: any) => a + b, 0),
    price: tickets[0].price,
    eventLabel: label,
    location,
    longitude,
    latitude,
    safetyGuide: safetyTipsAndGuidelines,
    videoLink,
    startDate,
    endDate,
    additionalInformation: additionalInfo,
    tasks: tasks.map((task: TaskType) => ({
      ...task,
      category,
    })),
    tickets: tickets.map((ticket: TicketType) => ({
      ...ticket,
      itemIncude: ticket.includes,
      maxQuantity: ticket.max_quantity,
      merchandises: ticket.merchandises.map((merchandise: Record<any, any>) => ({
        url: merchandise.image.base64Data,
      })),
    })),
    media: media[0].image.base64Data,
    medias: thumbnails.map((thumbnail: Record<any, any>) => thumbnail.image.base64Data),
  }
}
