import { camelCase, mapKeys, snakeCase } from 'lodash'

import { KeyValueObj } from './object.types'
import { Base64FileType, TransformedFileType } from 'components'

export const convertKeyToSnakeCase = (data: any) => mapKeys(data, (__, key) => snakeCase(key))

export const convertKeyToCamelCase = (data: any) => mapKeys(data, (__, key) => camelCase(key))

export const convertToPlainJSObject = (observableObject: any) =>
  JSON.parse(JSON.stringify(observableObject))

export const removeEmptyProperties = (source: KeyValueObj): KeyValueObj => {
  return Object.entries(source).reduce((result, item) => {
    if (item[1]) result[item[0]] = item[1]

    return result
  }, Object.create({}))
}

export const isEmpty = (source: KeyValueObj): boolean => !Object.keys(source).length

export async function exportToBase64(
  filesOrUrls: Array<TransformedFileType | string>
): Promise<(TransformedFileType | Base64FileType)[]> {
  const processedFiles = await Promise.all(
    filesOrUrls.map(async (fileOrUrl) => {
      if (typeof fileOrUrl === 'string') {
        try {
          const response = await fetch(fileOrUrl)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.readAsDataURL(blob)

          return new Promise<Base64FileType>((resolve, reject) => {
            reader.onloadend = () => {
              const base64Data = reader.result

              resolve({
                id: new Date().getTime(),
                name: fileOrUrl,
                image: {
                  base64Data,
                },
              })
            }

            reader.onerror = reject
          })
        } catch (error) {
          console.error('Error fetching image:', error)
          throw new Error(error)
        }
      }

      if (fileOrUrl && fileOrUrl.ogFile != null) {
        const file = fileOrUrl as TransformedFileType
        const reader = new FileReader()
        reader.readAsDataURL(file.ogFile)

        return new Promise<Base64FileType>((resolve, reject) => {
          reader.onload = () => {
            const base64Data = reader.result

            resolve({
              id: file.id,
              name: file.name,
              image: {
                base64Data,
              },
            })
          }

          reader.onerror = reject
        })
      }

      return fileOrUrl
    })
  )

  // @ts-ignore
  return processedFiles
}
