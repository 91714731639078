import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CategoryType, LabelType } from 'types/global.types'
import { CurrencyType } from 'utils/getCurrencySymbol'

export const MediaOrFileType = types.union(types.frozen())

export const MultipleMediaType = types.maybeNull(types.array(MediaOrFileType))

export const Task = types.model('Task', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  taskDate: types.maybeNull(types.string),
  completed: types.optional(types.maybeNull(types.boolean), false),
  additionalLink: types.maybeNull(types.string),
  detail: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  createdAt: types.optional(types.maybeNull(types.string), ''),
  updatedAt: types.optional(types.maybeNull(types.string), ''),
})

export const Thumbnail = types.model('Thumbnail', {
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  cloudinaryID: types.optional(types.maybeNull(types.string), ''),
  s3Key: types.optional(types.maybeNull(types.string), ''),
  index: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export const Media = types.model('Media', {
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  cloudinaryID: types.optional(types.maybeNull(types.string), ''),
  s3Key: types.optional(types.maybeNull(types.string), ''),
  index: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

const AddOn = types.model('AddOn', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  addOnsType: types.maybeNull(types.number),
  includes: types.maybeNull(types.array(types.maybeNull(types.string))),
  durationInSeconds: types.maybeNull(types.number),
  AIUsageCredit: types.maybeNull(types.number),
  media: Media,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

const Creator = types.model('Creator', {
  id: types.maybeNull(types.number),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userLevel: types.maybeNull(types.number),
  birthday: types.maybeNull(types.string),
  weight: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  age: types.maybeNull(types.number),
  gender: types.maybeNull(types.string),
  occupation: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  confirmedAt: types.maybeNull(types.string),
  isPreferenceAnswered: types.boolean,
  AIBalance: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export const Ticket = types.model('Ticket', {
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currency: types.maybe(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  tooltip: types.optional(types.maybeNull(types.string), ''),
  overview: types.optional(types.maybeNull(types.string), ''),
  includes: types.maybeNull(types.array(types.maybeNull(types.string))),
  max_quantity: types.maybeNull(types.number),
  additionalInformation: types.optional(types.maybeNull(types.string), ''),
  merchandises: types.maybeNull(types.union(types.array(Media), MultipleMediaType)),
  createdAt: types.optional(types.maybeNull(types.string), ''),
  updatedAt: types.optional(types.maybeNull(types.string), ''),
  deleted_at: types.optional(types.maybeNull(types.string), ''),
})

export const SelectedChallengeModel = types.model('SelectedChallenge').props({
  id: types.maybeNull(types.number),
  category: types.maybeNull(
    types.enumeration<CategoryType>(['adventure', 'health', 'sport', 'wellness'])
  ),
  title: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  overview: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  currency: types.maybe(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  benefits: types.maybeNull(types.array(types.maybeNull(types.string))),
  requirements: types.maybeNull(types.array(types.maybeNull(types.string))),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  maxMembers: types.maybeNull(types.number),
  additionalInformation: types.optional(types.maybeNull(types.string), ''),
  eventStatus: types.optional(types.maybeNull(types.string), ''),
  price: types.maybeNull(types.number),
  joinedMembers: types.maybeNull(types.number),
  taskCount: types.maybeNull(types.number),
  progress: types.maybeNull(types.number),
  safetyTipsAndGuidelines: types.maybeNull(types.string),
  termAndCondition: types.maybeNull(types.string),
  label: types.maybe(types.enumeration<LabelType>(['indoor', 'outdoor', 'virtual'])),
  location: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  distance: types.maybeNull(types.number),
  type: types.maybeNull(types.number),
  videoLink: types.optional(types.maybeNull(types.string), ''),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  add_ons: types.maybeNull(AddOn),
  creator: types.maybeNull(Creator),
  media: types.maybeNull(types.union(Media, MediaOrFileType)),
  thumbnails: types.maybeNull(types.union(types.array(Thumbnail), MultipleMediaType)),
  tasks: types.maybeNull(types.array(Task)),
  tickets: types.maybeNull(types.array(Ticket)),
  merchandises: types.maybeNull(types.array(Media)),
})

export type TicketModelInstance = Instance<typeof Ticket>
export type TicketType = Omit<TicketModelInstance, symbol>
export type TicketModelSnapshot = SnapshotOut<typeof Ticket>

export type TaskModelInstance = Instance<typeof Task>
export type TaskType = Omit<TaskModelInstance, symbol>
export type TaskModelSnapshot = SnapshotOut<typeof Task>

export type MediaModelInstance = Instance<typeof Media>
export type MediaType = Omit<MediaModelInstance, symbol>
export type MediaModelSnapshot = SnapshotOut<typeof Media>

export type SelectedChallengeModelInstance = Instance<typeof SelectedChallengeModel>
export type SelectedChallengeType = Omit<SelectedChallengeModelInstance, symbol>
export type SelectedChallengeModelSnapshot = SnapshotOut<typeof SelectedChallengeModel>
