import { createPath } from './path-utils'
import { PathGenerator } from './path.types'

const USER_PATH = '/user'

export const userProfilePath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'profile' })
}

export const userSettingsPath: PathGenerator = () => {
  return createPath(USER_PATH, { suffix: 'settings' })
}
