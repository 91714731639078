import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

type MenuProps = {
  changeTab?: (number: number) => void
  numberTab?: number
  tabList: Array<any>
  icon?: string
}

export const SideMenu = (props: MenuProps) => {
  const { changeTab, numberTab, tabList } = props

  const { t } = useTranslation()

  const TabMenu = ({
    isActive = false,
    label,
    onClick,
    completed = false,
    icon,
    totalList,
  }: {
    isActive: boolean
    label: string
    onClick: () => void
    completed: boolean
    icon?: string
    totalList?: number
  }) => {
    const tabClass = classNames(
      'border rounded clickable mb-2 d-flex side-menu-item justify-content-between align-items-center side-menu-hover',
      { 'bg-primary-blue': isActive }
    )

    return (
      <div className={tabClass} onClick={onClick}>
        <span
          className={classNames(
            'd-flex align-items-center gap-2',
            isActive ? 'text-white' : 'text-black'
          )}
        >
          {icon && <i className={icon} />}
          <h5 className='m-0 lh-sm'>
            {label}
            {totalList && <span className='total-icon border ms-1'>{totalList}</span>}
          </h5>
        </span>
        {completed && <i className='icon-check icon-input' />}
      </div>
    )
  }

  return (
    <div className='border'>
      <div className='m-2'>
        <h4 className='text-black'>{t('Menu')}</h4>
        <p>{t('Set up your event')}</p>
        {tabList.map(({ label, completed, totalList, icon }, index) => (
          <TabMenu
            label={label}
            icon={icon}
            totalList={totalList}
            isActive={numberTab === index}
            key={index}
            onClick={() => changeTab(index)}
            completed={completed}
          />
        ))}
      </div>
    </div>
  )
}
