import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, challengeApi } from 'services/api'

import { ChallengeModel } from './challenge'
import { withSetValue } from './helpers/with-set-value'
import { ParticipantsModel } from './participants-store'
import { SelectedChallengeModel } from './selected-challenge'

export const ChallengeStoreModel = types
  .model('ChallengeStore')
  .props({
    challenges: types.array(ChallengeModel),
    challengesParticipants: types.array(ParticipantsModel),
    selectedChallenge: types.optional(SelectedChallengeModel, {}),
    totalPage: types.optional(types.number, 1),
    tabId: types.optional(types.number, 0),
  })
  .extend(withSetValue)
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    getChallenges: flow(function* (payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield challengeApi.getChallenges(payload)
      if (result.ok) applySnapshot(self.challenges, result.data.data.datas)
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),

    getChallengeDetails: flow(function* (id) {
      const result: ApiSuccessResult = yield challengeApi.getChallengesDetails(id)

      if (result.ok) {
        applySnapshot(self.selectedChallenge, {})
        applySnapshot(self.selectedChallenge, result.data.data)
      }

      return result.ok
    }),

    deleteChallenge: flow(function* (id) {
      const result: ApiSuccessResult = yield challengeApi.deleteChallenges(id)

      return result.ok
    }),

    submitChallenge: flow(function* (payload?: any, id?: string) {
      const { ok, data }: ApiSuccessResult = id
        ? yield challengeApi.updateChallenges(id, payload)
        : yield challengeApi.addChallenges(payload)

      if (ok) {
        applySnapshot(self.selectedChallenge, {})

        return data.data.id
      }

      return false
    }),

    getChallengesParticipants: flow(function* (id, payload?) {
      if (payload?.search) {
        payload.q = payload.search
        delete payload.search
      }

      if (payload?.sorts) {
        payload.direction = payload.sorts[Object.keys(payload.sorts)[0]]
        payload.sort = Object.keys(payload.sorts)[0]
        delete payload.sorts
      }

      const result: ApiSuccessResult = yield challengeApi.getChallengesParticipants(id, payload)

      if (result.ok) applySnapshot(self.challengesParticipants, result.data.data.datas)
      self.totalPage = result.data.data.totalPages

      return result.ok
    }),

    resetSelectedChallenge: function () {
      applySnapshot(self.selectedChallenge, {})
    },
  }))

export type ChallengeStore = Instance<typeof ChallengeStoreModel>
export type ChallengeStoreSnapshot = SnapshotOut<typeof ChallengeStoreModel>
