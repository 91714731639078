import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'

import { useToggle } from 'hooks'
import { ProfileOption } from './types'
import Icon from 'components/icon'

type ProfileDropdownProps = {
  menuItems: Array<ProfileOption>
  userImage: string
  username: string
  userTitle?: string
}

const ProfileDropdown: FunctionComponent<ProfileDropdownProps> = (props) => {
  const [isOpen, toggleDropdown] = useToggle()

  const { menuItems, userImage, username, userTitle } = props

  return (
    <Dropdown show={isOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle
        variant='link'
        id='dropdown-profile'
        as={Link}
        to='#'
        onClick={toggleDropdown}
        className='nav-link dropdown-toggle nav-user arrow-none me-n2'
      >
        <span className='account-user-avatar'>
          <img src={userImage} className='rounded-circle avatar-sm me-1' alt='user' />
        </span>
        <span>
          <span className='account-user-name pe-4'>{username}</span>
          <span className='account-position'>{userTitle}</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align={'end'}
        className='dropdown-menu-animated topbar-dropdown-menu profile-dropdown'
      >
        <div className='d-flex flex-column m-1 gap-1' onClick={toggleDropdown}>
          {menuItems.map((item, i) => {
            return (
              <Link
                to={item.redirectTo}
                className='dropdown-item notify-item'
                key={i + '-profile-menu'}
              >
                <Icon icon={item.icon} size={20} />
                <span>{item.label}</span>
              </Link>
            )
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
