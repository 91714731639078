import { OptionProps } from 'components'
import { UserType } from 'types/global.types'
import { charity, company, individual } from 'assets/images'

export const NOT_AVAILABLE_TEXT = 'N/A'

export const MAX_TICKETS = 2

interface UserTypeConfig {
  level: number
  label: UserType
  value: UserType
}

export const USER_TYPE: Record<string, UserTypeConfig> = {
  SUPER_ADMIN: {
    level: 0,
    label: 'super-admin',
    value: 'super-admin',
  },
  ADMIN: {
    level: 1,
    label: 'admin',
    value: 'admin',
  },
}

export const CHALLENGE_LEVEL_OPTIONS: OptionProps[] = [
  { value: 'advanced', label: 'Advanced' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'beginner', label: 'Beginner' },
]

export const TASK_CATEGORY_OPTIONS: OptionProps[] = [
  { value: 'health', label: 'Health' },
  { value: 'sport', label: 'Sport' },
  { value: 'wellness', label: 'Wellness' },
  { value: 'food', label: 'Food' },
]

export const CHALLENGE_CATEGORYS_OPTIONS: OptionProps[] = [
  { value: 'wellness', label: 'Wellness', icon: 'wellness' },
  { value: 'sport', label: 'Sport', icon: 'sport' },
  { value: 'health', label: 'Health', icon: 'health' },
  { value: 'adventure', label: 'Adventure', icon: 'adventure' },
]

export const BANK_LIST: OptionProps[] = [
  { value: 'bri', label: 'Bank BRI' },
  { value: 'bca', label: 'Bank BCA' },
  { value: 'bni', label: 'Bank BNI' },
  { value: 'jago', label: 'Bank JAGO' },
]

export const CHALLENGE_STATUS_OPTIONS: OptionProps[] = [
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'ongoing', label: 'Ongoing' },
  { value: 'completed', label: 'Completed' },
]

export const CHALLENGE_MEMBER_OPTIONS: OptionProps[] = [
  { value: '50', label: '0-50' },
  { value: '100', label: '0-100' },
  { value: '150', label: '0-150' },
]

export const EVENT_LABEL_OPTIONS: OptionProps[] = [
  { value: 'outdoor', label: 'Outdoor', icon: 'outdoor' },
  { value: 'indoor', label: 'Indoor', icon: 'indoor' },
  { value: 'virtual', label: 'Virtual', icon: 'virtual' },
]

export const EVENT_PRICE_OPTIONS: OptionProps[] = [
  { value: 'usd', label: 'USD' },
  { value: 'idr', label: 'IDR' },
  { value: 'eur', label: 'EUR' },
]

export const CHECK_IN_STATUS_OPTIONS: OptionProps[] = [{ value: 'attended', label: 'Attended' }]

export const ListOrganizerType = [
  {
    type: 1,
    title: 'Individual',
    description: 'A person looking to host an event.',
    Image: individual,
  },
  {
    type: 2,
    title: 'Company',
    description: 'A legal entity registered in a jurisdiction.',
    Image: company,
  },
  {
    type: 3,
    title: 'Charity',
    description:
      'A charitable organisation, or trust, set up to provide help and raise money for those in need.',
    Image: charity,
  },
]
